<template>
  <div v-if="item" class="text-center">
    <v-tooltip top>
        <template v-slot:activator="{ on }">
            <v-icon small v-on="on" :color="bookingTypeColor(item)">{{ bookingTypeIcon(item) }}</v-icon>
        </template>
        <span style="font-size: 12px">{{
            $Format.capitalizeFirstLetter(item)
        }}</span>
    </v-tooltip>
    <span v-if="group" class="ml-1">{{ group }}</span>
  </div>
</template>
  
  <script>
export default {
  data: () => ({
    bookingTypes: [
      { value: "FREIGHT", icon: "mode_of_travel", color: "blue" },
      { value: "LOGISTICS", icon: "mode_of_travel", color: "blue" },
      { value: "DOCUMENTATION", icon: "article", color: "secondary" },
      { value: "HAULAGE", icon: "local_shipping", color: "blue-grey" },
      { value: "MONITORING", icon: "thermostat", color: "teal" },
    ],
    group: null,
    item: null,
  }),
  mounted() {
    this.item = this.params.value;
    if(this.params.node && this.params.node.group && this.params.node.key) {
      this.group = this.params.value;
    }
  },
  methods: {
    bookingTypeColor(type) {
      let find = this.bookingTypes.find((x) => x.value == type);
      return find ? find.color : null;
    },
    bookingTypeIcon(type) {
      let find = this.bookingTypes.find((x) => x.value == type);
      return find ? find.icon : null;
    },
  },
};
</script>
  
  <style>
</style>