<template>
  <div v-if="item">
    <v-tooltip top>
      <template v-slot:activator="{ on }">
       <v-icon small :color="statusColor(item)" v-on="on">
        {{ statusIcon(item) }}
       </v-icon>
      </template>
      <span style="font-size: 12px">{{
        $Format.capitalizeFirstLetter(item)
      }}</span>
    </v-tooltip>
    <span v-if="group" class="ml-1">{{ group }}</span>
  </div>
</template>

<script>
export default {
  data: () => ({
    item: null,
    group: null,
    statuses: [
      { name: "Draft", icon: "edit_note", color: "#F57C00" },
      { name: "Pending", icon: "pending", color: "#009688" },
      { name: "Requested", icon: "verified", color: "#2196F3" },
      { name: "Confirmed", icon: "check_circle_outline", color: "#4CAF50" },
      { name: "Ghost", icon: "help", color: "#607D8B" },
      { name: "Cancelled", icon: "cancel", color: "#F44336" },
      { name: "Rejected", icon: "warning", color: "#F44336" },
      { name: "Futile", icon: "warning", color: "#F44336" },
    ],
  }),
  mounted() {
    this.item = this.params.value;
    if(this.params.node && this.params.node.group && this.params.node.key) {
      this.group = this.params.value;
    }
  },
  methods: {
    statusColor(status) {
      let color = this.statuses.find(
        (x) => x.name.toLowerCase() === status.toLowerCase()
      );
      return color ? color.color : "grey";
    },
    statusIcon(status) {
      let find = this.statuses.find(
        (x) => x.name.toLowerCase() === status.toLowerCase()
      );
      return find ? find.icon : "";
    },
  }
};
</script>

<style>
</style>