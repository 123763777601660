<template>
  <div class="innerContent" style="height: 100%">
    <v-toolbar flat dense>
      <v-chip
        :key="dateKey"
        small
        label
        outlined
        class="primaryText--text mx-1 smallText"
        @click="changeDateType()"
      >
        <v-icon x-small left>event</v-icon> Date Type:
        {{ params.dateType }}
      </v-chip>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-chip
            small
            label
            outlined
            class="primaryText--text mx-1 smallText"
            v-on="on"
          >
            <v-icon x-small left>event</v-icon>
            {{
              quickSelectDates.find((x) => x.value == params.selectedDate)
                ? quickSelectDates.find((x) => x.value == params.selectedDate)
                    .text
                : "Select Date"
            }}<v-icon small right>expand_more</v-icon>
          </v-chip>
        </template>
        <v-list style="overflow: hidden" dense>
          <v-list-item
            v-for="(date, index) in quickSelectDates"
            :key="index"
            @click="selectDate(date.value)"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ date.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu
        v-if="params.selectedDate == 'select_dates'"
        ref="menu"
        v-model="dateMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            color="primary"
            class="mx-1"
            outlined
            label
            v-bind="attrs"
            v-on="on"
            small
            ><v-icon x-small left>date_range</v-icon>{{ dateText }}
            <v-icon small right>expand_more</v-icon></v-chip
          >
        </template>
        <v-date-picker
          v-model="params.dates"
          range
          color="primary"
          show-week
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary"> Cancel </v-btn>
          <v-btn text color="primary" @click="setDateFilter"> OK </v-btn>
        </v-date-picker>
      </v-menu>
      <v-spacer></v-spacer>
<v-chip small v-if="updatedBookings.length>0" @click="saveMassEditModal = true">
  <v-icon small left color="primary">save</v-icon>
  Save Changes ({{ updatedBookings.length }})
</v-chip>

      <v-spacer></v-spacer>
        <v-chip class="mr-1 searchChipWrap" style="max-width:550px">
          <v-btn small icon left color="primary" @click="addBooking()"
            ><v-icon>add_circle_outline</v-icon></v-btn
          >
          <v-btn small icon left color="teal" @click="addNewBooking()"
            ><v-icon>add_circle_outline</v-icon></v-btn
          >
        <v-icon small color="grey" class="ml-1">search</v-icon>
          <v-text-field
            placeholder="Search"
            class="mb-1 searchInput"
            hide-details
            rounded
            clearable
            dense
            style="margin-top: 2px; width: 300px"
            v-model="search"
          ></v-text-field>

          <v-tooltip top v-if="filterActive">
          <template v-slot:activator="{ on }">
            <v-btn
              rounded
              right
              small
              icon
              color="redPop"
              @click="resetFilters"
              v-on="on"
            >
              <v-icon>filter_alt_off</v-icon>
            </v-btn>
          </template>
          <span style="font-size: 12px">Clear Filters</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
             icon
              right
              small
              color="blue"
              @click="getBookings"
              v-on="on"
            >
              <v-icon>autorenew</v-icon>
            </v-btn>
          </template>
          <span style="font-size: 12px">Refresh Bookings</span>
        </v-tooltip>
       
        </v-chip>
    </v-toolbar>
    <ag-grid-vue
      groupDisplayType="multipleColumns"
      :animateRows="false"
      :rowGroupPanelShow="'always'"
      :autoSizeStrategy="{
        type: 'fitCellContents',
        defaultMinWidth: 150,
        columnLimits: [],
      }"
      style="width: 100%; height: 95%"
      :rowSelection="'multiple'"
      :enableRangeSelection="true"
      :allowContextMenuWithControlKey="true"
      :undoRedoCellEditing="true"
      :undoRedoCellEditingLimit="25"
      :columnDefs="columns"
      :rowData="bookings.data"
      :getContextMenuItems="getContextMenuItems"
      :getMainMenuItems="getMainMenuItems"
      :suppressAggFuncInHeader="true"
      :defaultColDef="defaultColDef.def"
      :sideBar="sidebar"
      :statusBar="statusBar"
      :initialState="initialState"
      :getRowId="getRowId"
      @cellValueChanged="onCellValueChanged"
      @state-updated="onStateUpdated"
      @grid-ready="onGridReady"
    >
    </ag-grid-vue>

    <v-dialog
      v-model="bookingModal"
      width="90vw"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <BookingModal
        :key="refreshBooking"
        :bookingObj="booking"
        :related="organisations"
        :relatedLoading="loadingOrganisations"
        @close="(bookingModal = false), (booking = {})"
      />
    </v-dialog>

    <v-dialog
      v-model="updatedBookingModal"
      fullscreen
      persistent
    >
      <UpdatedBookingModal
        :key="refreshBooking"
        :setBooking="booking"
        :organisations="organisations.data"
        @close="(updatedBookingModal = false), (booking = {})"
      />
    </v-dialog>

    <v-dialog v-model="saveMassEditModal" width="500px">
      <v-card>
        <v-toolbar flat dense></v-toolbar>
        <v-card-text>
          <p>
            Are you sure you want to apply the current changes to {{updatedBookings.length}} bookings? WARNING This action cannot be reversed.
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="red"
              class="mx-2"
              text
              small
              @click="saveMassEditModal = false"
              >No</v-btn
            >
            <v-btn
              color="success"
              class="mx-2"
              text
              small
              :loading="savingMassEdit"
              @click="saveMassEdit()"
              >Yes</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
/* eslint-disable vue/no-unused-components */
import ActionPanel from "./BookingComponents/ActionPanel.vue";
import { AgGridVue } from "ag-grid-vue";
import BookingModal from "../Bookings/BookingModal.vue";
import UpdatedBookingModal from './BookingModal.vue'
import BookingState from "./BookingComponents/BookingState.vue";
import BookingType from "./BookingComponents/BookingType.vue";
import dateFormat from "dateformat";
import * as moment from "moment";
import MovementType from "./BookingComponents/MovementType.vue";
import OrganisationName from "../GridComponents/Organisation.vue";
import PortCell from "../GridComponents/Port.vue";
import TableHeader from "../GridComponents/TableHeader.vue";
import UserCell from "../GridComponents/User.vue";
export default {
  components: {
    AgGridVue,
    ActionPanel,
    BookingModal,
    BookingState,
    BookingType,
    MovementType,
    OrganisationName,
    PortCell,
    agColumnHeader: TableHeader,
    UpdatedBookingModal,
    UserCell,
  },
  data: () => ({
    booking: {},
    bookingModal: false,
    bookings: {},
    bookingTimeout: null,
    bookingQuery: null,
    columns: [],
    dateKey: 200,
    dateMenu: false,
    defaultColDef: {
      def: {
        sortable: true,
        resizable: true,
        filter: true,
        enableRowGroup: true,
        enablePivot: true,
        flex: 1,
        minWidth: 100,
        cellClass: "d-flex align-center",
        cellStyle: { textAlign: "center", fontSize: "11px" },
      },
    },
    filterActive: false,
    filterSetting: {},
    gridApi: null,
    gridColumnApi: null,
    initialState: {},
    loading: false,
    loadingClone: false,
    loadingOrganisations: {},
    params: {
      dateType: "ETD",
    },
    organisations: {
      data: [],
      count: 0,
    },
    quickSelectDates: [
      {
        text: "Specify Dates",
        type: "current",
        value: "select_dates",
        difference: 0,
      },
      // {
      //     text: 'Past 30 days',
      //     type: 'past',
      //     value: 'past_month',
      //     difference: -1
      // },
      {
        text: "Past 7 Days",
        value: "past_week",
        type: "past",
        difference: -7,
      },
      {
        text: "This week",
        type: "current",
        value: "current_week",
        difference: 0,
      },
      {
        text: "Next 2 weeks",
        type: "future",
        value: "2_week",
        difference: 0,
      },
      {
        text: "Next 6 weeks",
        type: "future",
        value: "6_week",
        difference: 0,
      }
    ],
    refreshBooking: 100,
    saveMassEditModal: false,
    savingMassEdit: false,
    search: null,
    searchTimeout: null,
    selectedTeams: [],
    sidebar: {},
    statusBar: {
      statusPanels: [
        {
          statusPanel: "agTotalRowCountComponent",
          align: "left",
        },
        {
          statusPanel: "agFilteredRowCountComponent",
        },
        {
          statusPanel: "agSelectedRowCountComponent",
        },
        {
          statusPanel: "agAggregationComponent",
        },
      ],
    },
    updatedBookingModal: false,
    updatedBookings: [],
  }),
  watch: {
    search(val) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(async () => {
        this.gridApi.updateGridOptions({ quickFilterText: val });
      }, 400);
    },
  },
  created() {
    if (
      localStorage["bookingsTableState_" + this.$store.state.currentOrg.id]
    ) {
      let state = JSON.parse(localStorage["bookingsTableState_" + this.$store.state.currentOrg.id]);
      if (typeof state === "object" && state !== null) {
        this.initialState = state;
      }
    }
    if (localStorage["teams_" + this.$store.state.currentOrg.id]) {
        this.params.allTeams = JSON.parse(
          localStorage["teams_" + this.$store.state.currentOrg.id]
        );
      }
    if (localStorage["bookingTeams_" + this.$store.state.currentOrg.id]) {
        this.selectedTeams = JSON.parse(
          localStorage["bookingTeams_" + this.$store.state.currentOrg.id]
        );

      }
    if (localStorage["bookingSearchParams_"+this.$store.state.currentOrg.id]) {
      this.params = JSON.parse(localStorage["bookingSearchParams_"+this.$store.state.currentOrg.id]);
      if (!this.params) {
        this.params = {};
      }
      if (!this.params.dateType) {
        this.params.dateType = "ETD";
      }
      if(this.params.selectedDate && this.params.selectedDate != 'select_dates') {
        this.selectDate(this.params.selectedDate)
      }
    } else {
      this.params = {
        dateType: "ETD",
        selectedDate: "current_week",
        dates: [],
      };
      this.selectDate("current_week");
    }
    this.sidebar = {
      toolPanels: [
        {
          id: "actions",
          labelDefault: "Actions",
          labelKey: "actions",
          iconKey: "chart",
          toolPanel: "ActionPanel",
          minWidth: 300,
          maxWidth: 500,
          width: 300,
          toolPanelParams: {
            setTeamFilter: (teams, allTeams) => {
              this.selectedTeams = teams;
              this.params.allTeams=allTeams
              this.getBookings();
            },
            resetTable: this.resetTable,
            exportExcel: () => {
              this.gridApi.exportDataAsExcel();
            },
            exportCSV: () => {
              this.gridApi.exportDataAsCsv();
            },
          },
        },
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          maxWidth: 225,
          width: 225,
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
      defaultToolPanel: "ActionPanel",
    };
    this.columns = [
      //   {
      //     headerName: "Action",
      //     field: "actions",
      //   },
      {
        headerName: "Order No",
        field: "orderNo",
      },
      {
        headerName: "Status",
        field: "state",
        cellRenderer: "BookingState",
        cellStyle: { textAlign: "center", justifyContent: "center" },
      },
      {
        headerName: "Movement Type",
        field: "movementType",
        cellRenderer: "MovementType",
        cellStyle: { textAlign: "center", justifyContent: "center" },
      },
      {
        headerName: "Type",
        field: "type",
        cellRenderer: "BookingType",
        cellStyle: { textAlign: "center", justifyContent: "center" },
      },

      {
        headerName: "User",
        field: "userFullName",
        cellRenderer: "UserCell",
        cellRendererParams: (p) => {
          return p.data && p.data.user
            ? {
                name: p.data.user.firstname,
                surname: p.data.user.surname,
                avatar: p.data.user.avatar,
              }
            : null;
        },
      },

      {
        headerName: "Vessel",
        field: "vesselVoyage",
      },
      {
        headerName: "Regime Code",
        field: "regimeCode",
      },
      {
        headerName: "Customer",
        field: "customerName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.customer
            ? {
                organisation: p.data.customer,
                clientStatus: p.data.clientStatus,
              }
            : null;
        },
      },
      {
        headerName: "Shipper",
        field: "shipperName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.shipper
            ? {
                organisation: p.data.shipper,
              }
            : null;
        },
      },
      {
        headerName: "On Behalf Shipper",
        field: "onBehalfShipperName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.onBehalfShipper
            ? {
                organisation: p.data.onBehalfShipper,
              }
            : null;
        },
      },
      {
        headerName: "Consignee",
        field: "consigneeName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.consignee
            ? {
                organisation: p.data.consignee,
              }
            : null;
        },
      },
      {
        headerName: "Carrier",
        field: "shippingLineName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.shippingLine
            ? {
                organisation: p.data.shippingLine,
              }
            : null;
        },
      },
      {
        headerName: "Week",
        field: "sailingWeekText",
        cellStyle: {justifyContent: 'center'},
        valueGetter: (p)=>{
          return p.data && p.data.sailingWeek ? p.data.sailingWeekYear + ' - W'+ p.data.sailingWeek : null
        }
      },
      {
        headerName: "POL",
        field: "portOfLoadValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.portOfLoadValue : null,
            name: p.data ? p.data.portOfLoadCity : null,
          };
        },
      },
      {
        headerName: "ETD",
        field: "etd",
        cellEditor: 'agDateStringCellEditor',
        editable: true,
        cellClassRules: {
            'cell-flash-permanent': params => params.data.flashETD
          },
        valueGetter: (params) => {
          if (params.data && params.data.etd) {
            let date = new Date(params.data.etd);
            if (date.getUTCHours() === 0 && date.getUTCMinutes() === 0) {
              return this.formatDate(params.data.etd);
            } else {
              return this.formatDateTime(params.data.etd);
            }
          } else {
            return "";
          }
        },
      },
      {
        headerName: "ATD",
        field: "atd",
        cellEditor: 'agDateStringCellEditor',
        editable: true,
        cellClassRules: {
            'cell-flash-permanent': params => params.data.flashATD
          },
        valueGetter: (params) => {
          if (params.data && params.data.atd) {
            let date = new Date(params.data.atd);
            if (date.getUTCHours() === 0 && date.getUTCMinutes() === 0) {
              return this.formatDate(params.data.atd);
            } else {
              return this.formatDateTime(params.data.atd);
            }
          } else {
            return "";
          }
        },
      },
      {
        headerName: "POD",
        field: "portOfDischargeValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.portOfDischargeValue : null,
            name: p.data ? p.data.portOfDischargeCity : null,
          };
        },
      },
      {
        headerName: "Dest.",
        field: "finalDestinationValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.finalDestinationValue : null,
            name: p.data ? p.data.finalDestinationCity : null,
          };
        },
      },
      {
        headerName: "ETA",
        field: "eta",
        cellEditor: 'agDateStringCellEditor',
        editable: true,
        cellClassRules: {
            'cell-flash-permanent': params => params.data.flashETA
          },
        valueGetter: (params) => {
          if (params.data && params.data.eta) {
            let date = new Date(params.data.eta);
            if (date.getUTCHours() === 0 && date.getUTCMinutes() === 0) {
              return this.formatDate(params.data.eta);
            } else {
              return this.formatDateTime(params.data.eta);
            }
          } else {
            return "";
          }
        },
      },
      {
        headerName: "ATA",
        field: "ata",
        cellEditor: 'agDateStringCellEditor',
        editable: true,
        cellClassRules: {
            'cell-flash-permanent': params => params.data.flashATA
          },
        valueGetter: (params) => {
          if (params.data && params.data.ata) {
            let date = new Date(params.data.ata);
            if (date.getUTCHours() === 0 && date.getUTCMinutes() === 0) {
              return this.formatDate(params.data.ata);
            } else {
              return this.formatDateTime(params.data.ata);
            }
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Legs",
        field: "vesselLegs",
        cellStyle: {justifyContent: 'center'},
        valueGetter: (params) => {
          return params.data && params.data.sailingSchedule
            ? params.data.sailingSchedule.legs
            : 1;
        },
      },
      {
        headerName: "Containers",
        field: "numContainers",
        cellStyle: {justifyContent: 'center'},
      },
      {
        headerName: "Carrier Ref. No",
        field: "carrierReferenceNumber",
      },
      {
        headerName: "Shipper Ref. No",
        field: "shipperReferenceNo",
      },
      {
        headerName: "Consignee Ref. No",
        field: "consigneeReferenceNo",
      },
      {
        headerName: "INCO Term",
        field: "incoTerm",
      }
    ];
  },
  computed: {
    dateText() {
      if (this.params.dates.length === 0) {
        return "Select Dates";
      } else if (this.params.dates.length === 1) {
        return "Week: " + moment(this.params.dates[0]).isoWeek();
      } else {
        return `${this.params.dates[0]} - ${this.params.dates[1]}`;
      }
    },
  },
  async mounted() {
   
   
  },
  methods: {
    addBooking() {
      this.booking = {
        status: "Draft",
        transportType: "Container",
        movementType: "EXPORT",
        type: "LOGISTICS",
        modeOfTransport: "OCEAN",
        serviceType: "FCL",
        bookingContainerItems: [],
        bookingCargoItems: [],
        bookingPaymentDetails: [],
        productServiceSales: [],
      };
      this.bookingModal = true;
      this.refreshBooking++;
    },
    addNewBooking(){
      this.booking = {
        status: "Draft",
        transportType: "Container",
        movementType: "EXPORT",
        type: "LOGISTICS",
        modeOfTransport: "OCEAN",
        serviceType: "FCL",
        bookingContainerItems: [],
        bookingCargoItems: [],
        bookingPaymentDetails: [],
      };
      this.updatedBookingModal = true;
      this.refreshBooking++;
    },
    async cargoSummary(booking) {
      let bookingContainers = await this.$API.getBookingContainers(booking.id);
      let result = [];
      let availableMilestones = [
        { value: "EMPTY_PICKUP", type: "EXPORT", name: "Empty Pickup" },
        { value: "LOADING_POINT_1", type: "EXPORT", name: "Loading Point 1" },
        { value: "LOADING_POINT_2", type: "EXPORT", name: "Loading Point 2" },
        { value: "WEIGH_BRIDGE", type: "EXPORT", name: "Weigh Bridge" },
        { value: "FULL_DROP_OFF", type: "EXPORT", name: "Port Holding" },

        { value: "FULL_PICKUP", type: "IMPORT", name: "Full Pickup" },
        { value: "DELIVERY_POINT_1", type: "IMPORT", name: "Delivery Point 1" },
        { value: "DELIVERY_POINT_2", type: "IMPORT", name: "Delivery Point 2" },
        { value: "DELIVERY_POINT_3", type: "IMPORT", name: "Delivery Point 3" },
        { value: "EMPTY_DROP_OFF", type: "IMPORT", name: "Empty Drop Off" },
      ];
      if (booking && bookingContainers) {
        let containers = bookingContainers.filter(
          (x) =>
            !x.isDeleted &&
            (x.status == "Allocated" || x.status == null) &&
            x.bookingId == booking.id
        );
        containers.forEach((x) => {
          let containerProducts = x.containerProducts.map((y) => y.productId);
          containerProducts = containerProducts.sort((a, b) => a - b);
          let temporaryProducts = x.temporaryProducts.map(
            (y) => y.product + y.variation
          );
          let milestones = x.containerMilestones
            .filter((y) =>
              availableMilestones.map((z) => z.value).includes(y.type)
            )
            .map((y) => y.type + y.poiId);
          temporaryProducts = temporaryProducts.sort();
          x.transportType = x.isPrivateHaulage
            ? "Merchant"
            : x.isCarrierHaulage
            ? "Carrier"
            : x.isRailage
            ? "Rail"
            : "Other";
          x.hash =
            x.gensetRequired +
            x.containerTypeCode +
            x.preAdviseRequired +
            x.verificationMethod +
            x.transportCoordinatorId +
            x.transportType +
            containerProducts.join("_") +
            temporaryProducts.join("_") +
            milestones.join("_");
        });
        let uniqueCombinations = [...new Set(containers.map((x) => x.hash))];
        for (let i = 0; i < uniqueCombinations.length; i++) {
          let filtered = containers.filter(
            (x) => x.hash == uniqueCombinations[i]
          );
          let detail = filtered[0];

          let products = [].concat.apply(
            [],
            containers.map((x) => x.containerProducts)
          );
          detail.containerProducts.forEach((x) => {
            delete x.id;
            delete x.bookingContainerId;
            delete x.createdAt;
            delete x.updatedAt;
          });
          detail.containerMilestones.forEach((x) => {
            delete x.id;
            delete x.bookingContainerId;
            delete x.date;
            delete x.time;
            delete x.createdAt;
            delete x.updatedAt;
          });
          detail.temporaryProducts.forEach((x) => {
            delete x.id;
            delete x.bookingContainerId;
            delete x.createdAt;
            delete x.updatedAt;
          });
          let obj = {
            quantity: filtered.length,
            containerTypeCode: detail.containerTypeCode,
            isPrivateHaulage: detail.isPrivateHaulage,
            preAdviseRequired: detail.preAdviseRequired,
            isCarrierHaulage: detail.isCarrierHaulage,
            isRailage: detail.isRailage,
            transportCoordinatorId: detail.transportCoordinatorId,
            transportCoordinator: detail.transportCoordinator,
            bookingCargoItems: detail.containerProducts,
            containerMilestones: detail.containerMilestones,
            temporaryProducts: detail.temporaryProducts,
            vents: detail.vents,
            setPointTemp: detail.setPointTemp,
            humidity: detail.humidity,
            type:
              booking.serviceType == "BREAKBULK" ? "BREAKBULK" : "CONTAINER",
            index: i,
            consignee: detail.consignee,
            consigneeId: detail.consigneeId,
            buyer: detail.buyer,
            buyerId: detail.buyerId,
            verificationMethod:
              booking.movementType == "EXPORT"
                ? "Method 2"
                : detail.verificationMethod,
          };
          result.push(obj);
        }
      }
      return result;
    },
    onCellValueChanged(params) {
      const rowNode = this.gridApi.getRowNode(params.data.id);
      if (rowNode) {
        params.data['flash'+params.colDef.field.toUpperCase()] = true
        this.gridApi.refreshCells({ force: true });
      }
      let findPallet = this.updatedBookings.findIndex(
        (x) => x.id == params.data.id
      );
      if (findPallet > -1) {
        this.updatedBookings[findPallet][params.colDef.field] = params.newValue;
      } else {
        this.updatedBookings.push({
          id: params.data.id,
          [params.colDef.field]: params.newValue,
        });
      }
    },
    changeDateType() {
      if (this.params.dateType == "ETD") {
        this.params.dateType = "ETA";
      } else {
        this.params.dateType = "ETD";
      }
      localStorage.setItem("bookingSearchParams_"+this.$store.state.currentOrg.id, JSON.stringify(this.params));
      this.dateKey++;
      this.getBookings();
    },
    async cloneBooking(item) {
      this.loadingClone = true;
      let booking = await this.$API.getBookingDetail(item.systemReference);
      if (booking.consigneeProfileId) {
        booking.consigneeProfile = await this.$API.getConsigneeProfile(
          booking.consigneeProfileId
        );
      }
      let cargoDetails = await this.cargoSummary(booking);
      cargoDetails.forEach((detail) => {
        detail.containerMilestones = detail.containerMilestones.filter(
          (milestone) =>
            milestone.type !== "PLUG_IN" && milestone.type !== "PLUG_OUT"
        );
        detail.bookingCargoItems.forEach((item) => {
          delete item.id;
          delete item.bookingContainerId;
          delete item.createdAt;
          delete item.updatedAt;
        });
      });
      console.log("cargoDetails", cargoDetails);
      delete booking.id;
      delete booking.shipperReferenceNo;
      delete booking.consigneeReferenceNo;
      delete booking.carrierReferenceNumber;
      delete booking.integrationReference;
      delete booking.integrationChange;
      delete booking.qxReference;
      delete booking.q67ExcelId;
      delete booking.q67Excel;
      delete booking.q67Id;
      delete booking.q67;

      delete booking.systemReference;
      delete booking.user;
      delete booking.userId;
      delete booking.orderNo;
      delete booking.createdAt;
      delete booking.updatedAt;
      delete booking.exportNotificationStatus;
      delete booking.exportNotificationData;
      delete booking.exportNotificationReference;
      booking.bookingPaymentDetails.forEach((x) => {
        delete x.id;
        delete x.bookingId;
        delete x.createdAt;
        delete x.updatedAt;
      });
      this.booking = { ...booking };
      this.booking.bookingContainerItems = cargoDetails;
      this.booking.status = "Draft";
      // this.booking.bookingContainerItems= []
      this.loadingClone = false;
      this.bookingModal = true;
      this.refreshBooking++;
    },
    async getBookings() {
      if (this.bookingTimeout) {
        clearTimeout(this.bookingTimeout);
      }
      if (this.loading && this.bookingQuery) {
        this.bookingQuery.abort();
      }
      this.loading = true;    
      let params = { ...this.params };
      params.teams = this.selectedTeams;
      if(this.params.allTeams) {
        delete params.teams
      }
      if (this.params.dates.length == 1) {
        params.dates = [
          moment(this.params.dates[0])
            .startOf("isoWeek")
            .toISOString()
            .substring(0, 10),
          moment(this.params.dates[0])
            .endOf("isoWeek")
            .toISOString()
            .substring(0, 10),
        ];
      }
      this.bookingTimeout = setTimeout(async () => {
        this.bookingQuery = new AbortController();
        const signal = this.bookingQuery.signal;
        this.bookings = await this.$API.bookingOverviewUpdated({
          params: params,
          signal,
        });
        this.loading = false;
      }, 500);
    },
    getMainMenuItems(params) {
      let result = [
        "sortAscending",
        "sortDescending",
        "sortUnSort",
        "separator",
        "pinSubMenu",
        "separator",
        "autoSizeThis",
        "autoSizeAll",
        "separator",
        "rowGroup",
        "rowUnGroup",
      ];

      return result;
    },
    getContextMenuItems(params) {
      let result = [];
      if (!params.node.group) {
        result = [
          {
            name: "Manage Booking",
            icon: '<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: grey; margin-bottom: 0px; padding-bottom: 0px">launch</span>',
            action: () => {
              if (params.node && params.node.data && params.node.data.id) {
                this.viewBooking(params.node.data);
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          {
            name: "Manage Booking (NEW)",
            icon: '<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: grey; margin-bottom: 0px; padding-bottom: 0px">launch</span>',
            action: () => {
              if (params.node && params.node.data && params.node.data.id) {
                this.viewBookingNew(params.node.data);
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          {
            name: "Clone Booking",
            icon: '<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: grey; margin-bottom: 0px; padding-bottom: 0px">content_copy</span>',
            action: () => {
              if (params.node && params.node.data && params.node.data.id) {
                this.cloneBooking(params.node.data);
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          "separator",
          {
            name: "Add to Filter",
            icon: '<span class="ag-icon ag-icon-filter" unselectable="on" role="presentation"></span>',
            action: () => {
              if (params.node && params.node.data) {
                let filter = this.gridApi.getFilterModel();
                if (filter[params.column.colId]) {
                  filter[params.column.colId].values.push(params.value);
                } else {
                  filter[params.column.colId] = {
                    values: [params.value],
                  };
                }
      console.log("Filter event", filter)
                this.gridApi.setFilterModel(filter);
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          "separator",
          "autoSizeAll",
          "expandAll",
          "contractAll",
          "resetColumns",
          "copy",
          "copyWithHeaders",
          "export",
          "chartRange",
        ];
      } else {
        result = [
          "autoSizeAll",
          "expandAll",
          "contractAll",
          "resetColumns",
          "copy",
          "copyWithHeaders",
          "export",
          "chartRange",
        ];
      }

      return result;
    },
    postProcessPopup(params) {
      if (params.type !== "columnMenu") {
        return;
      }
      const ePopup = params.ePopup;
      ePopup.style.top = "65px";
      ePopup.style.left =
        params.column.actualWidth / 2 + params.mouseEvent.left + "px";
    },
    filterListener(event) {
      console.log("Filter event", event)
      let filter = this.gridApi.getFilterModel();
      this.filterActive = Object.keys(filter).length > 0;
      this.filterSetting = filter;
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "yyyy-mm-dd");
      }
      return result;
    },
    formatDateTime(date) {
      let result = null;
      if (date) {
        result = dateFormat(date, "yyyy-mm-dd HH:MM");
      }
      return result;
    },
    async getRelations() {
      this.loadingOrganisations = true;
      this.organisations = await this.$API.getRelationBasic({
        // params: this.params,
      });
      this.loadingOrganisations = false;
    },
    getRowId(params) {
      return params.data.id;
    },
    async load() {
      this.getBookings();
      await this.getRelations();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.addEventListener("filterChanged", this.filterListener);
      this.load();
    },
    onStateUpdated(state) {
        localStorage.setItem(
        "bookingsTableState_" + this.$store.state.currentOrg.id,
        JSON.stringify(state.state)
      );    
    },
    async resetFilters() {
      this.gridApi.setFilterModel({});
    },
    resetTable() {
      this.gridApi.setFilterModel(null);
      this.gridApi.setRowGroupColumns([]);
      this.gridApi.applyColumnState({ state: this.columns });
      this.gridApi.refreshCells({ force: true });
    },
    async saveMassEdit(){
      this.savingMassEdit = true;
      for(let i = 0; i < this.updatedBookings.length; i++){
        let findNode = this.gridApi.getRowNode(this.updatedBookings[i].id);
        if(findNode){
          this.updatedBookings[i].eta = findNode.data.eta
          this.updatedBookings[i].etd = findNode.data.etd
          this.updatedBookings[i].ata = findNode.data.ata
          this.updatedBookings[i].atd = findNode.data.atd
        }
      }
      await this.$API.bulkUpdateBookings(this.updatedBookings);
      this.$message.success("Bookings updated successfully!");
      // let nodes= []
      // for(let i = 0; i < this.updatedBookings.length; i++){
      //   let findNode = this.gridApi.getRowNode(this.updatedBookings[i].id);
      //   if(findNode){
      //     let keys = Object.keys(this.updatedBookings[i]).filter(x=>x!='id')
      //     for(let j = 0; j < keys.length; j++){
      //       findNode.setRowData('flash'+keys[j].toUpperCase(), false)
      //     }
      //     nodes.push(findNode)
      //   }
      // }
      this.gridApi.refreshCells({ force: true });
      this.updatedBookings = [];
      this.saveMassEditModal = false;
      this.savingMassEdit = false;
    },
    setDateRange(val) {
      switch (val) {
        case "current_3day":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_month":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 30))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "current_week":
          this.params.dates = [
            new Date(
              new Date().setDate(new Date().getDate() - new Date().getDay() + 1)
            )
              .toISOString()
              .substring(0, 10),
            new Date(
              new Date().setDate(new Date().getDate() - new Date().getDay() + 7)
            )
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "current_month":
          this.params.dates = [
            new Date(new Date().setDate(1)).toISOString().substring(0, 10),
            new Date().toISOString().substring(0, 10),
          ];
          break;
        case "past_day":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_3day":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 3))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 7))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 30))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "today":
          this.params.dates = [
            new Date().toISOString().substring(0, 10),
            new Date().toISOString().substring(0, 10),
          ];
          break;
        case "tomorrow":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "next_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 7))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "2_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 14))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "6_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 42))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "next_month":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 30))
              .toISOString()
              .substring(0, 10),
          ];
          break;
      }
      this.getBookings();
    },
    selectDate(value) {
      this.params.selectedDate = value;
      this.setDateRange(value);
      localStorage.setItem("bookingSearchParams_"+this.$store.state.currentOrg.id, JSON.stringify(this.params));
    },
    setDateFilter() {
      if (this.params.dates.length == 2) {
        if (this.params.dates[1] < this.params.dates[0]) {
          let dates = [this.params.dates[1], this.params.dates[0]];
          this.params.dates = dates;
        }
      }
      localStorage.setItem("bookingSearchParams_"+this.$store.state.currentOrg.id, JSON.stringify(this.params));
      this.dateMenu = false;
      this.getBookings();
    },
    viewBooking(item) {
      this.$router.push({
        path: "/booking/" + item.systemReference,
      });
    },
    viewBookingNew(item) {
      this.$router.push({
        path: "/booking/manage/" + item.systemReference,
      });
  },
}
}
</script>
<style>
.searchChipWrap {
  width: 70%;
  display: grid;
  transition: width 0.3s ease-in-out;
}
.innerContent {
  height: 100%;
  width: 100%;
}
.cell-flash-permanent {
  background-color: #2a5b84 !important;
}
</style>